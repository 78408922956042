import { useEffect, useState } from "react";
import { SERVER_CODE } from "../App";

export const useGetPlayers = (): {
  loading: boolean;
  players: number | null;
} => {
  const [players, setPlayers] = useState<null | number>(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPlayers = async () => {
      try {
        const data = await fetch(
          `https://servers-frontend.fivem.net/api/servers/single/${SERVER_CODE}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "GET",
          },
        );

        const res = await data.json();

        setPlayers(res.Data.clients);
      } catch (err) {
        console.log(err);
        setPlayers(null);
      } finally {
        setLoading(false);
      }
    };

    getPlayers();

    const interval = setInterval(async () => {
      await getPlayers();
    }, 10_000);

    return () => clearInterval(interval);
  }, []);

  return { players, loading };
};
