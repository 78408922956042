import Header from "./components/header";
import Links from "./components/links";
import Players from "./components/players";
import { useGetPlayers } from "./hooks/useGetPlayers";

export const MAX_PLAYERS = 666;
export const SERVER_CODE = "2edela";

function App() {
  const { players } = useGetPlayers();

  const joinServer = () => {
    window.open(`fivem://connect/${SERVER_CODE}`, "_blank");
  };
  return (
    <div
      className="min-h-screen flex flex-col px-5 font-akrobat overflow-x-hidden relative md:gap-y-20"
      style={{
        backgroundImage: `url("background.png")`,
        backgroundSize: "cover",
      }}
    >
      <Header />
      <Links />
      <Players players={players} />
      <button
        onClick={joinServer}
        className="py-2 bg-white w-full md:w-1/2 text-center text-3xl font-semibold tracking-widest uppercase absolute left-1/2 -translate-x-1/2 rounded-t-3xl bottom-0"
        style={{
          boxShadow: `0 0 30px 10px #fff`,
        }}
      >
        Join Us
      </button>
    </div>
  );
}

export default App;
