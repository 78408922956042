export interface ButtonProps {
  backgroundImage: string;
  text: string;
  href: string;
}
const Button: React.FC<ButtonProps> = ({ backgroundImage, text, href }) => {
  return (
    <a
      className="w-full h-24 md:p-0 p-4 rounded-tr-3xl rounded-bl-3xl font-akrobat md:font-semibold font-bold text-xl md:text-lg relative overflow-hidden shadow-2xl flex justify-center items-center"
      href={href}
      target="_blank"
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      rel="noreferrer"
    >
      <div className="absolute inset-0 bg-[#1984DD]/20" />
      <h1 className="relative text-white text-2xl tracking-widest uppercase">
        {text}
      </h1>
    </a>
  );
};

export default Button;
