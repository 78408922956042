import { MAX_PLAYERS } from "../App";
import Divider from "./divider";

const Players: React.FC<{ players: number | null }> = ({ players }) => (
  <div className="flex flex-col items-center justify-center ">
    <Divider />
    <div className="relative text-center text-white tracking-widest text-3xl md:text-6xl flex gap-x-4 items-center mt-10 flex-col uppercase overflow-hidden mb-20 md:overflow-visible">
      <div
        className="flex items-center gap-x-4"
        style={{ textShadow: "0px 0px 5px #fff" }}
      >
        <span className="font-thin">Total</span>
        <span className="font-bold ">Players</span>
      </div>
      <div className="tracking-normal mt-4">
        <h1>
          {players} / {MAX_PLAYERS}
        </h1>
      </div>
      <div className="absolute inset-0 scale-125 opacity-20 md:scale-[1.1]">
        <div className="flex items-center gap-x-4">
          <span className="font-thin">Total</span>
          <span className="font-bold ">Players</span>
        </div>
        <div className="tracking-normal mt-4">
          <h1>
            {players} / {MAX_PLAYERS}
          </h1>
        </div>
      </div>
    </div>
  </div>
);

export default Players;
