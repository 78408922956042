import { ButtonProps } from "./button";
import Button from "./button";
import Divider from "./divider";
const links: ButtonProps[] = [
  {
    backgroundImage: "discord.png",
    text: "Join server",
    href: "https://discord.gg/opticro",
  },
  {
    backgroundImage: "rules.png",
    text: "Server rules",
    href: "https://regulament.opticgaming.ro",
  },
  {
    backgroundImage: "rules.png",
    text: "Staff rules",
    href: "https://staff.opticgaming.ro",
  },
  {
    backgroundImage: "sanctiuni.png",
    text: "Sanctiuni",
    href: "https://sanctiuni.opticgaming.ro",
  },
];
const Links: React.FC = () => (
  <div className="flex items-center justify-center w-full md:h-40 flex-col md:flex-row">
    <Divider />
    <div className="flex flex-col items-center justify-center w-full px-10 mt-4 gap-y-2 md:flex-row md:gap-x-4">
      {links.map((link, i) => (
        <Button key={i} {...link} />
      ))}
    </div>
  </div>
);

export default Links;
