import Divider from "./divider";

const Header: React.FC = () => {
  return (
    <div className="flex items-center justify-center flex-col md:flex-row md:h-32 md:mt-40 md:gap-x-10 md:justify-normal">
      <Divider />
      <div className="flex flex-col text-white mt-4">
        <h1 className="text-2xl tracking-widest font-thin drop-shadow">
          Optic Romania Roleplay
        </h1>
        <h1 className="text-4xl font-black tracking-widest">New Generation</h1>
      </div>
      <div className="-order-1 md:order-1 relative w-3/4 md:w-1/4">
        <div className="absolute inset-0 scale-[1.1]">
          <img src="optic-logo.png" alt="logo" className="opacity-10 " />
        </div>
        <img src="optic-logo.png" alt="logo" />
      </div>
    </div>
  );
};

export default Header;
